<template>
  <component :is="is" ref="element">
    <slot></slot>
    <div
      v-if="content"
      ref="contentElement"
      class="pointer-events-none flex h-6 items-center whitespace-nowrap rounded bg-black px-2 text-center text-3xs text-white shadow-md"
      v-html="content"
    ></div>
    <div v-else ref="contentElement">
      <slot name="content"></slot>
    </div>
  </component>
</template>

<script setup lang="ts">
import tippy, { type Props } from 'tippy.js'
import 'tippy.js/animations/shift-away-subtle.css'

const {
  is = 'div',
  placement,
  offset = [2, 2],
  ...props
} = defineProps<
  Partial<{
    allowHTML: boolean
    animation: string | boolean
    content: string | Element
    inertia: boolean
    maxWidth: number | string
    role: string
    theme: string
    zIndex: number
    placement: Props['placement']
    offset?: [number, number]
  }> & { is?: keyof HTMLElementTagNameMap }
>()

const element = ref<HTMLElement>()
const contentElement = ref<HTMLElement>()

watchEffect(() => {
  if (!element.value) return
  tippy(element.value, {
    ...props,
    offset,
    placement,
    arrow: true,
    allowHTML: true,
    animation: 'shift-away-subtle',
    delay: 400,
    duration: 100,
    content: contentElement.value
  })
})
</script>
